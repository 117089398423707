<template>
  <div class="divider" style="background-color: black; height:2px;"/>
    <div v-if="forwarderId === '이지영'" id="cart-wrapper" class="mypage-wrapper">
      <div style="width: 150px;"> 
        <img id='cart-img' class="margin10 link" :src="(order.IMG_URL === '' || undefined) ? './assets/geek9_logo.jpg' : order.IMG_URL"/>
      </div>
      <div id="cart-container" >
        <div class="mypage-cart-wrapper"> 
          <p><label>주문날짜</label>{{ this.api.getDateByFormat(order.REG_DATE.substring(0,8), 'YYYY.MM.DD') }}</p>
          <p>
            <label>진행상태</label>{{ this.api.getOrderStatus(order.STATUS) }}
          </p>
          <p><label>정산여부</label>{{ (order.NOTICE.indexOf('입금완료') !== -1 || order.NOTICE.indexOf('결제완료') !== -1 || order.NOTICE.indexOf('정산완료') !== -1) ? '정산완료(' + this.api.getDateByFormat(order.NOTICE.substring(order.NOTICE.indexOf('(') + 1, order.NOTICE.indexOf(')')), 'YYYY/MM/DD') + ')' : '정산전'  }}</p>
        </div>
        <div class="mypage-cart-wrapper"> 
          <p><label>상품링크</label><a :href="order.URL" target="_blank">클릭</a></p>
          <p class="tracking-label">
            <label>추적번호</label>
            <input class="tracking-input" type="text" ref="trackingNumber" :value="order.TRACKING_NUMBER" placeholder="Tracking Number" />
            <Button size="small" label="저장" @click="click({type:'UPDATE_ORDER_BY_FORWARDER', val:this.$refs.trackingNumber.value, condition:'trackingNumber'})" /> 
          </p>
        </div>
      </div>
    </div>
    <div v-else-if="forwarderId === '대배송'" id="cart-wrapper" class="mypage-wrapper">
      <div style="width: 150px;">
        <img id='cart-img' class="margin10 link" :src="(order.IMG_URL === '' || undefined) ? './assets/geek9_logo.jpg' : order.IMG_URL"/>
      </div>
      <div id="cart-container" >
        <div class="mypage-cart-wrapper"> 
          <p class="div_title2"><label>주문날짜</label>
            <span style="text-align: center; width:100px;">{{ this.api.getDateByFormat(order.REG_DATE.substring(0,8), 'YYYY.MM.DD') }}</span></p>
          <p class="div_title2">
            <label>진행상태</label>
            <span style="text-align: center; width:100px;">{{ this.api.getOrderStatus(order.STATUS) }}</span>
          </p>
          <p class="div_title2">
            <label>해외가격</label>
            <span style="text-align: center; width:100px;">{{ Number(order.ORIGIN_PRICE).toLocaleString() }} {{ this.api.getCurrency(order.COUNTRY) }}</span>
          </p>
          <p class="div_title2">
            <label>대행유형</label>
            <span style="text-align: center; width:100px;">{{ order.ORDER_TYPE }}</span>
          </p>
          <p class="div_title2">
            <label>상품링크</label>
            <span style="text-align: center; width:100px;"><a :href="order.URL" target="_blank">클릭</a></span>
          </p>
          <p class="div_title2">
            <label>구매수량</label>
            <span style="text-align: center; width:100px;">{{ order.AMOUNT }} EA</span>
          </p> 
          <p class="tracking-label">
            <label>요청사항</label>
          </p>
          <Textarea v-model="notice" autofocus fluid style="width:90%;font-size:14px;" :value="order.NOTICE || '내용없음'" autoResize disabled  />
        </div>
        <div class="mypage-cart-wrapper"> 

          <p class="div_title2">
            <label>주문번호</label>
            <input class="tracking-input" type="text" ref="orderNumber" style="font-size: 14px;" :value="order.ORDER_NUMBER" placeholder="Order Number" />
            <Button size="small" label="저장" @click="click({type:'UPDATE_ORDER_BY_FORWARDER', val:this.$refs.orderNumber.value, condition:'orderNumber'})"  /> 
          </p>
          <p class="div_title2">
            <label>추적번호</label>
            <input class="tracking-input" type="text" ref="trackingNumber" style="font-size: 14px;" :value="order.TRACKING_NUMBER" placeholder="Tracking Number" />
            <Button size="small" label="저장" @click="click({type:'UPDATE_ORDER_BY_FORWARDER', val:this.$refs.trackingNumber.value, condition:'trackingNumber'})" /> 
          </p>
          <p class="div_title2">
            <label>해외배송</label>
            <input class="tracking-input" type="text" ref="deliveryPrice" :value="order.DELIVERY_PRICE2" style="font-size: 14px;" placeholder="배송비" />
            <Button size="small" label="저장" @click="click({type:'UPDATE_ORDER_BY_FORWARDER', val:this.$refs.deliveryPrice.value, condition:'deliveryPrice2'})" /> 
          </p>
          <p class="div_title2">
            <label>송장번호</label>
            <input class="tracking-input" type="text" ref="invoiceNumber" :value="order.INVOICE_NUMBER" style="font-size: 14px;" placeholder="송장번호" />
            <Button size="small" label="저장" @click="click({type:'UPDATE_ORDER_BY_FORWARDER', val:this.$refs.invoiceNumber.value, condition:'invoiceNumber'})" /> 
          </p>
          <!-- <p>
            <Select style="margin-right:10px;" v-model="deliveryKrCompany" :options="this.api.DELVERY_KR_COMPANY" optionLabel="name" placeholder="배송업체" />
            <input class="tracking-input " type="text" ref="invoiceNumber" :value="order.INVOICE_NUMBER" placeholder="송장번호"  />
            <Button size="small" label="저장" @click="click({type:'UPDATE_ORDER_INVOICE'})" /> 
          </p> -->
          <!-- <p class="tracking-label">
            <label>요청사항</label>
          </p>
          <Textarea v-model="notice" autofocus fluid style="width:90%;" :value="order.NOTICE" autoResize disabled  /> -->
        </div>
      </div>
    </div>
    <!-- <div class="div_title"> -->
      <!-- <span style="margin-left:20px;">메시지</span> -->


    <!-- </div> -->
    <!-- <div style="display: flex; justify-content: center; width: 100%;">
      <Textarea v-model="notice" autofocus fluid style="width:95%;" :value="order.NOTICE" autoResize disabled  />
    </div> -->
  <div class="divider" />
</template>
<script>
export default {
  data() { 
    return { 
      notice:'',

    }
  },
  props: {
    forwarderId:String,
    order:{}
  },
  methods: {
    async click(param) {
      const type = param.type
      if(type === 'UPDATE_ORDER_BY_FORWARDER') {
        const val = param.val
        param.idx = this.order.IDX
        if(val === undefined || val === '') this.emitter.emit('confirm', {message: '값을 입력해주세요.', header:this.api.getCookies('page'), icon:'pi pi-exclamation-triangle'})
        else {
          if(confirm("값을 업데이트할까요?")) {
            await this.api.axiosGql(type, param)
            .then( async (res) => { 
              if(res.status === 200) this.emitter.emit('confirm', {message: '변경되었습니다.', header:this.api.getCookies('page'), icon:'pi pi-info-circle'})
              else alert('다시 시도해주세요')
            })
          }
        }
      }
    }
  }
}
</script>

<style scoped>
/* .mypage-cart-wrapper > p > label {
  color:gray;
  margin-right:10px;
} */
.tracking-label {
  display: flex;
  align-items: center;
  width: 90%;
}

.label-text {
  white-space: nowrap;
  margin-right: 10px;
}

/* .tracking-input {
  flex: 1;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding:5px;
  margin-right: 10px;
  min-width: 0; 
} */
</style>